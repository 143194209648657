import React from "react";
import "./style.scss";
import data from "./data";
import Layout from "../../Layout";
import Seo from "../../seo.js";
const BlogListingPage = () => {
  return (
    <Layout>
      <Seo title="Chinmaya Vidyalaya | Blog" />
      <div className="blog-listing">
        <h1 className="heading">Blog</h1>
        <div className="blog-listing-items">
          {data.map((blog) => (
            <a href={blog.link} key={blog.id} className="blog-item">
              <h2>{blog.title}</h2>
              <p className="blog-date">{blog.date}</p>
            </a>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default BlogListingPage;
